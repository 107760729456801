<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="编辑车场"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="车场名称" v-bind="validateInfos.name">
        <a-input
          v-model:value="parkingCurbVo.name"
          :maxlength="20"
          placeholder="车场名称"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="企业编号" v-bind="validateInfos.ltdCode">
        <a-input
          v-model:value="parkingCurbVo.ltdCode"
          :maxlength="50"
          placeholder="企业编号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="车场编号" v-bind="validateInfos.code">
        <a-input
          disabled
          v-model:value="parkingCurbVo.code"
          :maxlength="50"
          placeholder="车场编号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="总车位数" v-bind="validateInfos.total">
        <a-input
          :maxlength="11"
          v-model:value="parkingCurbVo.total"
          placeholder="总车位数"
        ></a-input>
      </a-form-item>
      <a-form-item label="收费规则" >
        <a-select
          v-model:value="parkingCurbVo.parkingLotBillRuleId"
          placeholder="收费规则"
          allow-clear
        >
          <a-select-option
            v-for="item in billRules"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          v-model:value="parkingCurbVo.status"
          placeholder="请选择"
          allow-clear
          :options="parkingStatus"
        ></a-select>
      </a-form-item>
      <a-form-item label="地址" v-bind="validateInfos.address">
        <a-input
          allow-clear
          v-model:value="parkingCurbVo.address"
          placeholder="地址"
        ></a-input>
      </a-form-item>
      <a-form-item label="纬经度" v-bind="validateInfos.lngLat">
        <a-input
          allow-clear
          v-model:value="parkingCurbVo.lngLat"
          placeholder="填写格式：经度,纬度"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, reactive, toRefs, watch } from "vue";
import useParkingClosed from "@/hooks/parking/useParkingClosed";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { allBillRules } from "@/api/parking/parkingLotBillRuleApi";
import { edit, detail } from "@/api/parking/parkingLotApi";
import axios from "axios";
import { validNumber } from "../../../../utils/validateUtils";

const useForm = Form.useForm;
export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { parkingCurbVo, parkingStatus } = useParkingClosed();
    const billRules = reactive([]);
    const { parkingCloseRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      parkingCurbVo,
      parkingCloseRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        if (!parkingCurbVo.latitude || !parkingCurbVo.longitude) {
          showTipMessage("请填写正确的经纬度", "error");
          return;
        }
        if (!validNumber(parkingCurbVo.total)) {
          showTipMessage("请填写正确的总车位数", "error");
          return;
        }

        let action = await showConfirm("修改车场信息?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              name: parkingCurbVo.name,
              ltdCode: parkingCurbVo.ltdCode,
              code: parkingCurbVo.code,
              total: parkingCurbVo.total,
              type: parkingCurbVo.type,
              address: parkingCurbVo.address,
              longitude: parkingCurbVo.longitude,
              latitude: parkingCurbVo.latitude,
              status: parkingCurbVo.status,
              parkingLotBillRuleId: parkingCurbVo.parkingLotBillRuleId,
              parkingLotBillRuleName: parkingCurbVo.parkingLotBillRuleName,
            };
            await edit(props.id, obj);
            await closeLoading();
            await showTipMessage("修改成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      try {
        let [r1, r2] = await axios.all([allBillRules(), detail(props.id)]);
        billRules.push(...r1.data);
        parkingCurbVo.name = r2.data.name;
        parkingCurbVo.ltdCode=r2.data.ltdCode;
        parkingCurbVo.code = r2.data.code;
        parkingCurbVo.total = r2.data.total;
        parkingCurbVo.address = r2.data.address;
        parkingCurbVo.longitude = r2.data.longitude;
        parkingCurbVo.latitude = r2.data.latitude;
        parkingCurbVo.lngLat = r2.data.longitude + "," + r2.data.latitude;
        parkingCurbVo.status = r2.data.status;
        parkingCurbVo.parkingLotBillRuleId = r2.data.parkingLotBillRuleId;
        parkingCurbVo.parkingLotBillRuleName = r2.data.parkingLotBillRuleName;
      } catch (e) {
        await showErr(e);
      }
    });
    watch(parkingCurbVo, ({ lngLat, parkingLotBillRuleId }) => {
      parkingCurbVo.longitude = lngLat.split(",")[0];
      parkingCurbVo.latitude = lngLat.split(",")[1];
      if (parkingLotBillRuleId != null) {
        parkingCurbVo.parkingLotBillRuleName = billRules.filter(
          item => item.id == parkingLotBillRuleId,
        )[0].name;
      }

    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      billRules,
      parkingCloseRules,
      parkingCurbVo,
      parkingStatus,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
